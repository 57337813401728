<template>
  <div class="SecureLock">
    <span class="lock-container">
      <i class="material-icons md-20">security</i>
    </span>
    <span class="o-default-body-xs secure-text">{{ hostname }} {{ $t('secureLock.text') }}</span>
  </div>
</template>

<script>
export default {
  name: 'SecureLock',
  data() {
    return {
      hostname: window.location.hostname
    };
  }
};
</script>

<style lang="scss" scoped>
.SecureLock {
  color: $palette-primary-color-four;
  display: inline-block;
  margin: 4.8rem 0;
  width: 100%;

  .lock-container {
    @include round-corners(50%);
    border: .1rem solid $palette-primary-color-four;
    display: inline-block;
    height: 4rem;
    position: relative;
    width: 4rem;

    i {
      @include center-xy;
      position: absolute;
    }
  }

  .secure-text {
    color: $palette-primary-color-four;
    display: inline-block;
    line-height: 4.2rem;
    margin-left: .8rem;
    vertical-align: top;
  }
}
</style>
