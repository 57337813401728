<template>
  <div class="ActivateUserView">
    <div class="o-container-gray">
      <div v-if="isActivationLoading">
        <h3 class="u-text-center">{{ $t('activateUserView.activationInProgress') }}</h3>
        <Loader />
      </div>
      <div v-else>
        <div v-if="errors"
             v-html="errors"
             class="o-default-h3 u-default-body u-red">
        </div>
        <div v-else>
          <img src="@/assets/images/checkmark.png"
               alt="Checkmark image"
               class="o-image-medium" />
          <h3 class="o-default-h3 u-strong">{{ $t('activateUserView.accountActivated') }}</h3>
          <h3 class="o-default-h4">{{ $t('activateUserView.goToSignIn') }} </h3>
          <md-button class="md-dense md-raised md-primary navigate-btn" @click="Helpers.goToRoute('sign-in', $router)">
            {{ $t('common.auth.signIn') }}
          </md-button>
          <md-snackbar :md-position="snackbar.position"
                       :md-duration="snackbar.duration"
                       :md-active.sync="snackbar.show"
                       md-persistent>
            <span>{{ $t('snackbar.congratulations') }}</span>
          </md-snackbar>
        </div>
      </div>
      <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration" :md-active.sync="snackbar.showSnackbar" md-persistent>
        <span>{{ $t('snackbar.congratulations') }}</span>
      </md-snackbar>
    </div>
    <SecureLock />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ACTIVATE_USER } from '@/store/actions.type';
import SecureLock from '@/components/util/SecureLock';
import Loader from '@/components/util/Loader';


export default {
  name: 'ActivateUserView',
  components: {
    Loader,
    SecureLock
  },
  data() {
    return {
      activateToken: null,
      snackbar: {
        showSnackbar: false,
        position: 'center',
        duration: 4000
      }
    };
  },
  created() {
    this.activateToken = this.$route.query.activateToken;

    this.$store
      .dispatch(ACTIVATE_USER, {
        activateToken: this.activateToken
      });
  },
  mounted() {
    this.snackbar.showSnackbar = true;
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isActivationLoading: state => state.auth.isActivationLoading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.ActivateUserView {

  h2 {
    margin-top: 4rem;
  }

  .o-default-h3,
  .o-default-h4 {
    text-align: center;
  }

  .o-container-gray {
    margin-top: 0;
  }

  .navigate-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    margin-bottom: 1.6rem;
    text-transform: none;
  }
}
</style>
